import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {

  private _certificatelUrl=`${environment.baseUrl}/certificates` 
  constructor(private http: HttpClient) { }

  createCertificate(id):Observable<Object> {
    return this.http.get<any>(`${this._certificatelUrl}/create/${id}/`);
  }

  getCertificates() :Observable <any>
  {
   return this.http.get(`${this._certificatelUrl}`);
  }
  changeEtat(id : string):Observable<any>{
    return this.http.get<any>(`${this._certificatelUrl}/etat/${id}`);
  } 
  getCertificateById(id : string):Observable<any>
      {
        return this.http.get<any>(`${this._certificatelUrl}/${id}`);
    
      } 

  getCertificatesByUser() :Observable <any>
      {
       return this.http.get(`${this._certificatelUrl}/user`);
      }   

      getCertificatesByTest(id) :Observable <any>
      {
       return this.http.get(`${this._certificatelUrl}/byTest/${id}`);
      }   

  
// admin
  listeCertificateByInstallateurInAdminEspace(id):Observable<Object> {
    return this.http.get<any>(`${this._certificatelUrl}/installateur/${id}/`);
  }

  listeCertificateByDistributeurInAdminEspace(id):Observable<Object> {
    return this.http.get<any>(`${this._certificatelUrl}/distributeur/${id}/`);
  }
  getCertificatesByTraining(id : string):Observable<any>
  {
    return this.http.get<any>(`${this._certificatelUrl}/by/training/${id}`);

  }
  getCertificatesByTestLastOne(id : string):Observable<any>
  {
    return this.http.get<any>(`${this._certificatelUrl}/byTest/last/one/${id}`);

  }
}
